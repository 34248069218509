import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>     
      <Img fluid={data.imageOne.childImageSharp.fluid} className="img-thumbnail"/>
    </div>
    <div>
      <p className="properly-aligned">
        Fabien Naté Ngu-Mongala Vurutoro-te-Nzapa est né à Fadu, Mobayi Mbongo, dans la Province du <a href='https://fr.wikipedia.org/wiki/Nord-Ubangi' target='_blank' rel="noopener noreferrer">Nord-Ubangi</a>, en <a href='https://fr.wikipedia.org/wiki/R%C3%A9publique_d%C3%A9mocratique_du_Congo' target='_blank' rel="noopener noreferrer">République Démocratic du Congo</a>. Il a fait ses études
        primaires et petit séminaire à la Mission Catholique de Molegbe, mission dirigée par les Pères Franciscains
        (Capucins) du Vicariat de l'Ubangui. Il est breveté de l'<a href='https://fr.wikipedia.org/wiki/Institut_international_d%27administration_publique' target='_blank' rel="noopener noreferrer">Institut International d'Administration Publique</a> (Institut Supérieur et International d'Administration 
        Publique) de Paris en France. 
      </p>
      <p className="properly-aligned">
        Il est auteur de plusieurs recueils de poèmes et de récits qu'il a lui-même publiés sous forme polycopiée: <Link to={data.allBooksJson.nodes[0].link}>BANANES CITRONNEES suivies de CAPRICES</Link>, <Link to={data.allBooksJson.nodes[1].link}>{data.allBooksJson.nodes[1].book}</Link>, <Link to={data.allBooksJson.nodes[2].link}>{data.allBooksJson.nodes[2].book}</Link>. Il est co-auteur du recueil de poèmes <span className="black-bold">Quatre Poètes du Kivu</span>,
        imprimé à Kinshasa en 1974. Il est lauréat du concours littéraire organisé par le Centre Culturel Français de Bukavu; 
        concours littéraire d'où est sorti le recueil <span className="black-bold">Poètes du Kivu</span>, édité à Kinshasa par l'Ambassade Française. 
      </p>
    </div>
    <div>
    <h4 className='underline-title'>Publications</h4>
    <p>
    <Link to={data.allBooksJson.nodes[0].link}>{data.allBooksJson.nodes[0].book}</Link> - 1977<br />
    <Link to={data.allBooksJson.nodes[1].link}>{data.allBooksJson.nodes[1].book}</Link> - 1978<br />
    <Link to={data.allBooksJson.nodes[2].link}>{data.allBooksJson.nodes[2].book}</Link> - 1988<br />
    </p>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allBooksJson {
      nodes {
        book
        pages
        link
        pageInfo {
          node {
            link
            poemTitle
          }
        }
      }
    }
    imageOne: file(relativePath: { eq: "nate1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "nate2.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "nate3.jpg" }) {
      ...fluidImage
    }
  }
`
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

